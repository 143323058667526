/* stylelint-disable */
/* These are the styles from person-registration-app */
/* We copy them here in the format used in person-registration-app, so stylelint is disabled */
.signup {
    font-family: "Avenir Next", Avenir, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif;
    text-rendering: optimizeLegibility;
    color: #333;
}

.widget {
    background: #B1DAE7;
}


.widget {
    width: 300px;
    margin: auto;
    box-shadow: 0 0 4px 3px #ccc;
}



.widget-container {
    padding-bottom: 1px;
}

.widget-container .header {
    padding: 11px;
    background: #fff;

    box-sizing: border-box;

    overflow: hidden;

    text-align: center;
}

.widget-container .header img.dd-logo {
  height: 66px;
  margin: auto;
}

@media screen and (max-width: 480px) {
    .widget-container .header img.dd-logo {
        height: 60px;
    }
}

.widget-container .main {
	width: 100%;
	min-height: 350px;
    box-sizing: border-box;
    padding: 20px 20px 25px 20px;
    background: #B1DAE7;
}

.widget-container .main > p {
  box-sizing: border-box;
  margin: auto auto 22px auto;
  font-size: 15px;
  font-size: 15px;
  color: #333;
  line-height: 1.6;
  font-weight: 500;
  text-align: center;
}


/* Customize error messages */
  .auth0-global-message.auth0-global-message-error,
  .auth0-global-message.auth0-global-message-success {
  background: #fff;
  padding-top: 0;
  text-align: center;
  padding: 0 20px 6px 20px;
}

.auth0-global-message {
  color: rgba(221, 60, 93, 0.9);
  font-size: 14px;
  line-height: 1.5;
  text-transform: none;
  font-weight: 600;
}

.auth0-global-message.auth0-global-message-success {
  color: #009374;
}

@media screen and (max-width: 480px) {
  .widget {
      width: 100%;
      margin: 0;
      box-shadow: none;
  }
  
  .widget-container{
      height: 100%;
      position: absolute;
      top: 0;
      background: #B1DAE7;
  }
  
  body .background{
      background: #B1DAE7;
  }
}

@media screen and (min-width: 481px) {
  .header{
      -webkit-border-top-left-radius: 6px;
      -moz-border-top-left-radius: 6px;
      border-top-left-radius: 6px;
      -webkit-border-top-right-radius: 6px;
      -moz-border-top-right-radius: 6px;
      border-top-right-radius: 6px;
  }
  
  .widget {
      width: 300px;
      margin: auto;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      box-shadow: 0 0 4px 3px #ccc;
  }
}

.background {
    background: #E0EEF1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.9;
    -webkit-transition: opacity 0.3s ease-in 0s;
    transition: opacity 0.3s ease-in 0s;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.center-outer {
  display: table;
  width: 100%;
  height: 100%;
}

.center {
  display: table-cell;
  margin: auto;
  vertical-align: middle;
  overflow: auto;
}

.nodisplay {
  display: none;
}

/* Animated Check Mark & Cross Styles */
svg {
	width: 100px;
	display: block;
	margin: 40px auto 0;
}

.path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
}

.path.circle {
	-webkit-animation: dash 0.9s ease-in-out;
	animation: dash 0.9s ease-in-out;
}

.path.line {
	stroke-dashoffset: 1000;
	-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
	animation: dash 0.9s 0.35s ease-in-out forwards;
}

.path.check {
	stroke-dashoffset: -100;
	-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
	animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

/* does not seem to be needed
p {
	text-align: center;
	margin: 20px 0 60px;
	font-size: 1.25em;
}

p.success {
	color: #73af55;
}

p.error {
	color: #d06079;
}
*/

@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}

	100% {
		stroke-dashoffset: 900;
	}
}

@keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}

	100% {
		stroke-dashoffset: 900;
	}
}

/* disable animations for IE 11 which matches this media query */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.path.circle {
		-webkit-animation: none;
		animation: none;
	}

	.path.line {
		stroke-dashoffset: 0;
		-webkit-animation: none;
		animation: none;
	}

	.path.check {
		stroke-dashoffset: 0;
		-webkit-animation: none;
		animation: none;
	}
}
